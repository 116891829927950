import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Input } from 'BreetComponents';
import { closeModalById } from 'BreetConfig';
import { type IApiFormErrorResponse, type IUserReferral, spawnAppToast } from 'BreetHelpers';
import { selectModals, useAppSelector, useUpdateReferralMutation } from 'BreetRedux';
import { useCallback } from 'react';
import { useForm } from 'react-hook-form';

import { EditReferralFomSchema, type EditReferralFomSchemaType } from './SiteMgtReferral.helper';

const EditReferralForm = () => {
	const modals = useAppSelector(selectModals);

	const currentModal = modals.find((modal) => modal.id === 'editReferral');
	const modalMetadata = currentModal?.metadata as IUserReferral;

	const {
		register,
		handleSubmit,
		formState: { errors },
		setError,
	} = useForm({
		resolver: yupResolver(EditReferralFomSchema),
		defaultValues: {
			referralCode: modalMetadata.referralCode,
			points: modalMetadata.points || 0,
		},
	});

	const [updateReferral, { isLoading }] = useUpdateReferralMutation();

	const onSubmit = useCallback(
		(payload: EditReferralFomSchemaType) => {
			updateReferral({ ...payload, referralId: modalMetadata._id })
				.unwrap()
				.then((resp) => {
					spawnAppToast({ dataMsg: resp, type: 'success' });
					closeModalById('editReferral');
				})
				.catch((err: unknown) => {
					const errorObj = err as IApiFormErrorResponse;
					spawnAppToast({ dataMsg: err, type: 'error' });
					if (Array.isArray(errorObj.data.errors)) {
						errorObj.data.errors.forEach((error) => {
							setError(error.field as keyof EditReferralFomSchemaType, { message: error.message });
						});
					}
				});
		},
		[modalMetadata._id, setError, updateReferral]
	);

	return (
		<form
			onSubmit={handleSubmit(onSubmit)}
			className='modalFormWrapper'
		>
			<Input
				label='Referral Code'
				register={register('referralCode')}
				errorMsg={errors.referralCode?.message}
				placeholder={modalMetadata.referralCode}
			/>

			<Input
				type='number'
				label='Points'
				register={register('points')}
				placeholder={String(modalMetadata.points || 0)}
				errorMsg={errors.points?.message}
			/>

			<Button
				size='large'
				loading={isLoading}
			>
				Save
			</Button>
		</form>
	);
};

export default EditReferralForm;
